<template>
  <div class="col-12">
    <div class="row mx-0 font12">
      <div class="col-12 mb-3">
        I / We hereby request, "instruct" and authorise you to draw against my / our account with the above-mentioned bank stipulated amount. I / We understand that all such withdrawals from my / our bank account by you shall be treated as though they had been signed by me / us personally.
      </div>
      <div class="col-12 mb-3">
        We understand that the withdrawals hereby authorise will be processed by computer through a system known as the ACB Magnet Tape Service and I / We also understand that details of each withdrawal will be printed on my bank statement or on accompanying voucher.
      </div>
      <div class="col-12 mb-3">
        This authority may be cancelled by me / us by giving you thirty days' notice in writing. I / We shall not be entitled to any amounts, which you have withdrawn in terms of the above transaction while authority was in force.
      </div>
    </div>
  </div>
</template>
